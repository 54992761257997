import { render, staticRenderFns } from "./Standings.vue?vue&type=template&id=77271d25&scoped=true&"
import script from "./Standings.vue?vue&type=script&lang=js&"
export * from "./Standings.vue?vue&type=script&lang=js&"
import style0 from "./Standings.vue?vue&type=style&index=0&id=77271d25&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77271d25",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VIcon,VList,VListItem,VListItemTitle,VMenu,VTab,VTabs})
