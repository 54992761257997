<template>
  <div class="overall-stats-section" v-if="leagueStats">
    <div class="table-title">
      <span class="title-text">Yellow Card Away <span v-if="league">- {{league.competition.league}}</span></span>
    </div>
    <v-data-table
        class="league-stats-table"
        :headers="tableHeader()"
        :items="leagueStats"
        hide-default-footer
        loading-text="Lütfen bekleyiniz..."
        no-data-text="Sonuç bulunamadı!"
        dense
        disable-pagination
        :header-props="{ sortIcon: null, padding:0 }"
        single-select
        mobile-breakpoint=""
    >
      <!--      <template v-slot:header="{props}">
              <th style="background: red" v-for="(prop,i) in props.headers" :key="i">
                {{prop.text}}
              </th>
            </template>-->

      <template v-slot:item="{item,index}">
        <tr class="stats-table-tr">
          <td style="text-align: center">
            <span class="team-order">{{index + 1 }}</span>
          </td>
          <td @click="toTeamDetail(item.teamId)" class="team-logo-and-name">
            <img class="team-img"
                 :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${item.teamId}.png`" alt=""/>
            <span class="stats-team-name">{{item.teamName}}</span>
          </td>
          <td :tabindex="index">{{item.played}}</td>
          <td :tabindex="index">{{item.over05}}%</td>
          <td :tabindex="index">{{item.over15}}%</td>
          <td :tabindex="index">{{item.over25}}%</td>
          <td :tabindex="index">{{item.over35}}%</td>
          <td :tabindex="index">{{item.over45}}%</td>
          <td :tabindex="index">{{item.over55}}%</td>
          <td :tabindex="index">{{item.over65}}%</td>
          <td :tabindex="index">{{item.avg}}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {fetchLeagueStats} from "../../../api";

export default {
  name: "YellowCardAway",
  mounted() {
    this.fetchLeagueStats();
  },
  props:['league','seasonId'],
  data() {
    return {
      leagueStats: null,
      headersEN: [
        {sortable: false,text: '# ', value: '# ', width: '10px', class:"table-th",align:'center'},
        {sortable: false,text: 'Team', value: 'Team', width: '20%', class:'table-th', align:'left'},
        {sortable: true,text: 'MP', value: 'played', class:'table-th',align:'center'},
        {sortable: true,text: 'Over 0.5', value: 'over05', class:'table-th',align:'center'},
        {sortable: true,text: 'Over 1.5', value: 'over15', class:'table-th',align:'center'},
        {sortable: true,text: 'Over 2.5', value: 'over25', class:'table-th',align:'center'},
        {sortable: true,text: 'Over 3.5', value: 'over35', class:'table-th',align:'center'},
        {sortable: true,text: 'Over 4.5', value: 'over45', class:'table-th',align:'center'},
        {sortable: true,text: 'Over 5.5', value: 'over55', class:'table-th',align:'center'},
        {sortable: true,text: 'Over 6.5', value: 'over65', class:'table-th',align:'center'},
        {sortable: true,text: 'AVG', value: 'avg', class:'table-th',align:'center'},
      ],
      headersTR: [
        {sortable: false,text: '# ', value: '# ', width: '10px', class:"table-th",align:'center'},
        {sortable: false,text: 'Takım', value: 'Team', width: '20%', class:'table-th', align:'left'},
        {sortable: true,text: 'MP', value: 'played', class:'table-th',align:'center'},
        {sortable: true,text: '0.5 Üst', value: 'over05', class:'table-th',align:'center'},
        {sortable: true,text: '1.5 Üst', value: 'over15', class:'table-th',align:'center'},
        {sortable: true,text: '2.5 Üst', value: 'over25', class:'table-th',align:'center'},
        {sortable: true,text: '3.5 Üst', value: 'over35', class:'table-th',align:'center'},
        {sortable: true,text: '4.5 Üst', value: 'over45', class:'table-th',align:'center'},
        {sortable: true,text: '5.5 Üst', value: 'over55', class:'table-th',align:'center'},
        {sortable: true,text: '6.5 Üst', value: 'over65', class:'table-th',align:'center'},
        {sortable: true,text: 'AVG', value: 'avg', class:'table-th',align:'center'},
      ],
      tr: false,
      en: false,
    }
  },
  methods: {
    async fetchLeagueStats() {
      const data = await fetchLeagueStats(this.$route.params.seasonId,'YellowCardsAway');
      this.leagueStats = data.data.data;
    },
    toTeamDetail(teamId) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: teamId}});
      window.open(routeData.href, '_blank');
    },
    tableHeader() {
      if (localStorage.getItem('language') === 'tr') {
        this.tr = true;
        return this.headersTR
      } else {
        this.en = true;
        return this.headersEN
      }
    }
  }
}
</script>

<style>

.theme--light.v-data-table .v-data-table-header th.sortable:hover{
  background-color: #d2d2d2;
}

.theme--light.v-data-table .v-data-table-header th.sortable.active {
  background: rgba(0, 0, 0, 0.3) !important;
}

::v-deep table {
  border-collapse: separate !important;
}

.table-th {
  padding: 0 4px !important;
  height: 30px !important;
  background: #ddd;
}

.table-th:last-child {
  border-right: .1px solid rgba(0, 0, 0, 0.12);
}

.table-th span {
  color: #222222 !important;
  font-size: 14px;
}

.overall-stats-section {
  margin-bottom: 35px;
}

.table-title {
  width: 100%;
  background: #ddd;
  border-bottom: 2px solid #ccc;
  border-radius: 4px 4px 0 0;
}

.title-text {
  padding: 0.4em 0 0.3em 0.5em;
  font-size: 1.1em;
  font-weight: 600;
}

.team-logo-and-name {
  text-align: left !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.team-order {
  color: #222222;
  font-weight: 600;
}

.league-stats-table {
  margin-top: 10px;
}

.league-stats-table tr td{
  padding: 0 4px !important;
  height: 30px !important;
  text-align: center;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.league-stats-table tr{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.league-stats-table tr td:last-child {
  border-right: .1px solid rgba(0, 0, 0, 0.12);
}

.league-stats-table tr td:first-child {
  text-align: left;
}

/*.stats-table-tr {
  display: grid;
  grid-template-columns: minmax(150px,auto) repeat(9,70px);
  width: 100%;
}*/

.team-img {
  width: 14px;
  height: 14px;
}

tr:nth-child(even) {
  background-color: #f3f3f3;
}

.stats-team-name {
  text-align: left;
  color: #0066c0;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
  width: 100%;
}

</style>