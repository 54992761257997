<template>
  <div>
    <!--RESULTS-->
    <template v-if="tab===0">
      <v-skeleton-loader
          v-if="!data"
          class="rf_skeleton"
          type="table-heading@18"
      ></v-skeleton-loader>
      <template v-for="(r,ri) in data">
        <h2 :key="ri" class="fv_title">{{ r.round }}. Hafta</h2>
        <div :key="`h-${ri}`" class="c_header _header _accent--text">
          <div class="ch_date dis-center">Tarih</div>
          <div class="ch_home_team"></div>
          <div class="ch_score"></div>
          <div class="ch_away_team"></div>
          <div class="ch_half_time">İY</div>
          <div class="ch_corner">Korner</div>
          <div class="ch_under_over">A/Ü</div>
          <div class="ch_bts">KG</div>
        </div>
        <div class="result-for-web" v-for="match in r.matches" :key="match.id">
          <div class="c_match" >
            <div class="cm_date">{{ matchDate(match.dateTimeUtc) }}</div>
            <div class="cards">
              <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
              <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
            </div>
            <div class="cm_home_team">
              <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
            </div>
            <div class="cm_score">
              <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
                {{ match.hscoreFT }} - {{ match.ascoreFT }}
              </router-link>
            </div>
            <div class="cm_away_team">
              <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
            </div>
            <div class="cards">
              <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
              <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
            </div>
            <div class="cm_half_time">
              <span><span class="only_mobile">İY<br></span>{{ match.score1h }}</span>
            </div>
            <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.hcorner }} - {{ match.acorner }}
            </div>
            <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
            <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
            <div class="cm_match_status">
              <div></div>
            </div>
          </div>
        </div>

        <div class="result-for-mobile" v-for="match in r.matches" :key="match.id">
          <div class="match-mobile-container" >
            <div class="cm_date">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
            <div class="cm_home_team">
              <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
            </div>
            <div class="cm_score">
              <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
                {{ match.hscoreFT }} - {{ match.ascoreFT }}
              </router-link>
            </div>
            <div class="cm_away_team">
              <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
            </div>
            <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
            <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
          </div>
        </div>
      </template>
    </template>
    <!--FIXTURE-->
    <template v-if="tab===1">
      <v-skeleton-loader
          v-if="!data"
          class="rf_skeleton"
          type="table-heading@18"
      ></v-skeleton-loader>
      <template v-for="(r,ri) in data">
        <h2 :key="ri" class="fv_title">{{ r.round }}. Hafta</h2>
        <div :key="`h-${ri}`" class="c_header _header _accent--text">
          <div class="ch_date dis-center">Tarih</div>
        </div>
        <div class="fixture-for-web" v-for="match in r.matches" :key="match.id">
          <div class="cm_date">{{ matchDate(match.dateTimeUtc) }}</div>
          <div class="cards">
            <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
            <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
          </div>
          <div class="cm_home_team">
            <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
          </div>
          <div class="cm_score">
            <router-link :to="{name: 'MDCompare', params: {id: match.id}}" target="_blank">-</router-link>
          </div>
          <div class="cm_away_team">
            <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
          </div>
          <div class="cards">
            <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
            <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
          </div>
          <div class="cm_half_time"></div>
          <div class="cm_corner"></div>
          <div class="cm_under_over"></div>
          <div class="cm_bts"></div>
          <div class="cm_match_status"></div>
        </div>

        <div class="fixture-for-mobile" v-for="match in r.matches" :key="match.id">
          <div class="cm_date">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
          <div class="cm_home_team">
            <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
          </div>
          <div class="cm_score">
            <router-link :to="{name: 'MDCompare', params: {id: match.id}}" target="_blank">-</router-link>
          </div>
          <div class="cm_away_team">
            <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {fetchMatches,fetchMatchesFixture} from "../../api";
import dayjs from "dayjs";

export default {
  name: "ResultsAndFixture",
  props: ['tab'],
  data() {
    return {
      data: null,
    }
  },
  mounted() {
    this.fetchMatches();
    this.fetchMatchesFixture();
  },
  methods: {
    async fetchMatches() {
      const data = await fetchMatches(this.$route.params.seasonId)
      if (this.tab === 0) {
        this.data = data.data.data;
      }
    },
    async fetchMatchesFixture() {
      const data = await fetchMatchesFixture(this.$route.params.seasonId)
      if (this.tab === 1) {
        this.data = data.data.data;
      }
    },

    matchDate(value) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    matchDateForMobile(value) {
      return dayjs(value).format("DD/MM/YY");
    },

    underOver(match) {
      if (match.ended) {
        return (match.hscoreFT + match.ascoreFT) > 2.5 ?
            {value: 'Ü', style: 'success--text'} :
            {value: 'A', style: 'error--text'}
      } else {
        return {value: '', style: ''};
      }
    },

    bts(match) {
      if (match.ended) {
        return (match.hscoreFT > 0 && match.ascoreFT > 0) ?
            {value: 'Var', style: 'success--text'} :
            {value: 'Yok', style: 'error--text'}
      } else {
        return {value: '', style: ''};
      }

    },

    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
  },
  watch: {
    $route() {
      this.data = null;
      this.fetchMatches();
      this.fetchMatchesFixture()
    },

    tab() {
      this.data = null;
      this.fetchMatches();
      this.fetchMatchesFixture()
    }
  }
}
</script>

<style scoped>
.cm_score, .cm_half_time, .cm_bts, .cm_under_over, .ch_bts, .ch_under_over, .ch_corner, .cm_corner, .ch_half_time {
  text-align: center;
}

.result-for-mobile {
  display: none;
}

.cm_bts, .cm_under_over {
  font-weight: bold;
}

.cm_score {
  font-weight: 800;
}

.cm_half_time, .cm_corner {
  color: #b2b2b2;
}

.cm_home_team, .cm_away_team {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cm_home_team span, .cm_away_team span {
  cursor: pointer;
}

.rf_skeleton {
  margin-top: 15px;
}

.c_match {
  display: flex;
}

.fixture-for-web {
  display: grid;
  line-height: 23px;
  padding: 0 10px;
  border-bottom: 1px solid #edf2f4;
  grid-template-columns: 60px 36px minmax(84px, 300px) 50px minmax(84px, 300px) 36px 45px 45px 30px 30px;
}

.fixture-for-mobile {
  display: none;
}

.cm_date {
  flex: 1;
}

.dis-center {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 650px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(120px, 300px) 50px minmax(120px, 300px) 45px 45px 30px 30px;
  }

  .c_header_league {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: auto 45px 45px 30px 30px;
  }

  .c_match {
    display: grid;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px 36px minmax(84px, 300px) 50px minmax(84px, 300px) 36px 45px 45px 30px 30px;
  }

  .cm_home_team {
    text-align: right;
  }

  .cm_home_team .cards {
    margin-left: auto;
  }

  .cm_half_time .only_mobile, .cm_corner .only_mobile {
    display: none;
  }

  .sub_tab.only_mobile {
    margin-left: auto;
  }

}

/*@media only screen and (max-width: 650px) {
  .c_header {
    display: grid;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px;
    grid-template-areas: "date teams score underover bts";
  }

  .ch_date {
    grid-area: date;
  }

  .dis-center {
    display: flex;
    justify-content: center;
  }

  .ch_score {
    grid-area: score
  }

  .ch_half_time {
    grid-area: halftime
  }

  .ch_under_over {
    grid-area: underover
  }

  .ch_bts {
    grid-area: bts
  }

  .ch_away_team {
    display: none
  }

  .ch_home_team {
    grid-area: teams
  }

  .c_match {
    display: grid;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px;
    grid-template-areas: "date hometeam score underover bts" "date awayteam score underover bts";
  }

  .ch_half_time, .ch_corner {
    display: none;
  }

  .cm_date {
    padding-top: 12px;
    grid-area: date;
  }

  .cm_home_team {
    grid-area: hometeam;
  }

  .cm_away_team {
    grid-area: awayteam;
  }

  .cm_score {
    padding-top: 12px;
    grid-area: score;
  }

  .cm_half_time {
    display: none;
  }

  .cm_corner {
    display: none;
  }

  .cm_under_over {
    padding-top: 12px;
    grid-area: underover;
  }

  .cm_bts {
    padding-top: 12px;
    grid-area: bts;
  }

  .cards {
    display: none;
  }

  .cm_match_status {
    display: none;
  }
}*/

@media only screen and (max-width: 426px) {

  .c_header {
    display: grid;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px;
    grid-template-areas: "date teams score underover bts";
  }

  .ch_away_team {
    display: none
  }
  .ch_half_time, .ch_corner {
    display: none;
  }
  .cm_half_time {
    display: none;
  }

  .cm_corner {
    display: none;
  }
  .cm_match_status {
    display: none;
  }

  .result-for-web {
    display: none;
  }

  .result-for-mobile {
    display: block;
  }

  .cm_home_team {
    text-align: right;
  }

  .match-mobile-container {
    display: grid;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(70px, 115px) 40px minmax(70px, 115px) 30px 30px;
    grid-template-areas: "date hometeam score underover bts" "date awayteam score underover bts";
  }

  .fixture-for-web {
    display: none;
  }

  .fixture-for-mobile {
    display: grid;
    padding: 0 10px;
    grid-template-columns: 60px minmax(70px, 150px) 40px minmax(70px, 150px);
  }

  .fv_title {
    margin-top: 0;
    font-size: 14px;
    height: 20px;
    font-weight: 400;
  }
}
</style>
