<template>
  <div class="stats-table-section">

    <div v-if="toggle === 0">
      <GoalOverall :seasonId="seasonId" :key="seasonId" :league="league"></GoalOverall>
      <GoalHome :seasonId="seasonId" :key="seasonId" :league="league"></GoalHome>
      <GoalAway :seasonId="seasonId" :key="seasonId" :league="league"></GoalAway>
      <Goal1Half :seasonId="seasonId" :key="seasonId" :league="league"></Goal1Half>
      <Goal2Half :seasonId="seasonId" :key="seasonId" :league="league"></Goal2Half>
      <GoalFor :seasonId="seasonId" :key="seasonId" :league="league"></GoalFor>
      <GoalAgainst :seasonId="seasonId" :key="seasonId" :league="league"></GoalAgainst>
    </div>

    <div v-if="toggle === 1">
      <CornerOverall :seasonId="seasonId" :key="seasonId" :league="league"></CornerOverall>
      <CornerHome :seasonId="seasonId" :key="seasonId" :league="league"></CornerHome>
      <CornerAway :seasonId="seasonId" :key="seasonId" :league="league"></CornerAway>
      <Corner1Half :seasonId="seasonId" :key="seasonId" :league="league"></Corner1Half>
      <Corner2Half :seasonId="seasonId" :key="seasonId" :league="league"></Corner2Half>
      <CornerFor :seasonId="seasonId" :key="seasonId" :league="league"></CornerFor>
      <CornerAgainst :seasonId="seasonId" :key="seasonId" :league="league"></CornerAgainst>
    </div>

    <div v-if="toggle === 2">
      <YellowCardOverall :seasonId="seasonId" :key="seasonId" :league="league"></YellowCardOverall>
      <YellowCardHome :seasonId="seasonId" :key="seasonId" :league="league"></YellowCardHome>
      <YellowCardAway :seasonId="seasonId" :key="seasonId" :league="league"></YellowCardAway>
      <YellowCard1Half :seasonId="seasonId" :key="seasonId" :league="league"></YellowCard1Half>
      <YellowCard2Half :seasonId="seasonId" :key="seasonId" :league="league"></YellowCard2Half>
    </div>

    <div v-if="toggle === 3">
      <TotalCardOverall :seasonId="seasonId" :key="seasonId" :league="league"></TotalCardOverall>
      <TotalCardHome :seasonId="seasonId" :key="seasonId" :league="league"></TotalCardHome>
      <TotalCardAway :seasonId="seasonId" :key="seasonId" :league="league"></TotalCardAway>
      <TotalCard1Half :seasonId="seasonId" :key="seasonId" :league="league"></TotalCard1Half>
      <TotalCard2Half :seasonId="seasonId" :key="seasonId" :league="league"></TotalCard2Half>
    </div>

  </div>
</template>

<script>
import CornerOverall from "./CornerStats/CornerOverall";
import CornerHome from "./CornerStats/CornerHome";
import CornerAway from "./CornerStats/CornerAway";
import Corner1Half from "./CornerStats/Corner1Half";
import Corner2Half from "./CornerStats/Corner2Half";
import CornerFor from "./CornerStats/CornerFor";
import CornerAgainst from "./CornerStats/CornerAgainst";
import GoalOverall from "./GoalStats/GoalOverall";
import GoalHome from "./GoalStats/GoalHome";
import GoalAway from "./GoalStats/GoalAway";
import Goal1Half from "./GoalStats/Goal1Half";
import Goal2Half from "./GoalStats/Goal2Half";
import GoalFor from "./GoalStats/GoalFor";
import GoalAgainst from "./GoalStats/GoalAgainst";
import YellowCardOverall from "./YellowCardStats/YellowCardOverall";
import YellowCardHome from "./YellowCardStats/YellowCardHome";
import YellowCardAway from "./YellowCardStats/YellowCardAway";
import YellowCard1Half from "./YellowCardStats/YellowCard1Half";
import YellowCard2Half from "./YellowCardStats/YellowCard2Half";
import TotalCardOverall from "./TotalCardStats/TotalCardOverall";
import TotalCardHome from "./TotalCardStats/TotalCardHome";
import TotalCardAway from "./TotalCardStats/TotalCardAway";
import TotalCard1Half from "./TotalCardStats/TotalCard1Half";
import TotalCard2Half from "./TotalCardStats/TotalCard2Half";
//import StatsTableHeaders from "./StatsTableHeaders";

export default {
  name: "StatsTable",
  props: ['toggle','league'],
  data() {
    return {
      seasonId: this.$route.params.seasonId,
      tr: false,
      en: false,
      tableStats:'goal'
    }
  },
  mounted() {
    /*this.setTableHeader('Goal');*/
  },
  methods: {
    /*setTableHeader(tableSt) {
      console.log('tableSt', tableSt);
      if (localStorage.getItem('language') === 'tr') {
        this.tr = true;
        console.log('StatsTableHeaders[0].headerTR', StatsTableHeaders[0].headerTR);
        return StatsTableHeaders[0].headerTR.map(stat => {
          console.log('stat.tableSt', stat[tableSt]);
          return stat[tableSt]
        });
      } else {
        console.log('StatsTableHeaders[0].headerEN', StatsTableHeaders[0].headerEN);
        return StatsTableHeaders[0].headerEN;
      }
    }*/
  },
  components: {
    CornerOverall,
    CornerHome,
    CornerAway,
    Corner1Half,
    Corner2Half,
    CornerFor,
    CornerAgainst,
    GoalOverall,
    GoalHome,
    GoalAway,
    Goal1Half,
    Goal2Half,
    GoalFor,
    GoalAgainst,
    YellowCardOverall,
    YellowCardHome,
    YellowCardAway,
    YellowCard1Half,
    YellowCard2Half,
    TotalCardOverall,
    TotalCardHome,
    TotalCardAway,
    TotalCard1Half,
    TotalCard2Half
  }
}
</script>

<style scoped>

.stats-table-section {
  margin: 10px 0;
  padding: 0 10px;
}

</style>