<template>
  <div>
    <div v-if="!haveGroups" class="filter_bar">
      <v-tabs hide-slider height="30" color="#41a775" v-model="tab" v-if="this.tr === getMenuItems()">
<!--        <v-tab class="sub_tab">Geniş</v-tab>-->
        <v-tab class="sub_tab">Genel</v-tab>
        <v-tab class="sub_tab">İç Saha</v-tab>
        <v-tab class="sub_tab">Dış Saha</v-tab>
      </v-tabs>
      <v-tabs hide-slider height="30" color="#41a775" v-model="tab" v-if="this.en === getMenuItems()">
<!--        <v-tab class="sub_tab">Large</v-tab>-->
        <v-tab class="sub_tab">General</v-tab>
        <v-tab class="sub_tab">Home</v-tab>
        <v-tab class="sub_tab">Away</v-tab>
      </v-tabs>
    </div>
    <div v-else class="filter_bar">
      <v-tabs hide-slider height="30" color="#41a775" v-model="tab" v-if="this.tr === getMenuItems()">
        <v-tab class="sub_tab">Genel</v-tab>
        <v-tab class="sub_tab">İç Saha</v-tab>
        <v-tab class="sub_tab">Dış Saha</v-tab>
      </v-tabs>
      <v-tabs hide-slider height="30" color="#41a775" v-model="tab" v-if="this.en === getMenuItems()">
        <v-tab class="sub_tab">General</v-tab>
        <v-tab class="sub_tab">Home</v-tab>
        <v-tab class="sub_tab">Away</v-tab>
      </v-tabs>
      <v-menu offset-y max-height="300" max-width="200">
        <template v-slot:activator="{ on }">
          <div class="select_container">
            <div class="league_select" v-on="on">
              <div>Grup: {{ selectedGroupModal }}</div>
              <v-icon>arrow_drop_down</v-icon>
            </div>
          </div>
        </template>
        <v-list dense>
          <v-list-item
              v-for="(g, index) in groupList"
              :key="index"
              @click="selectedGroupModal = g"
          >
            <v-list-item-title>{{ g }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <h2 class="fv_title mt-0" v-if="this.tr === getMenuItems()">Puan Durumu</h2>
    <h2 class="fv_title mt-0" v-if="this.en === getMenuItems()">Standings</h2>
    <div class="scroll_on_overflow">
<!--      <template v-if="!haveGroups">
        <template v-if="tab===0">
          <div v-if="compactStandings.length > 0" class="standing_container">
            <div class="season_name _header _accent&#45;&#45;text" v-if="this.tr === getMenuItems()">
              <div></div>
              <div></div>
              <div>Genel</div>
              <div>İç Saha</div>
              <div>Dış Saha</div>
            </div>
            <div class="season_name _header _accent&#45;&#45;text" v-if="this.en === getMenuItems()">
              <div></div>
              <div></div>
              <div>General</div>
              <div>Home Field</div>
              <div>Away Field</div>
            </div>
            <div class="standing_header _header _accent&#45;&#45;text">
              <div class="sh_num">#</div>
              <div class="sh_team_name">Takım</div>
              <div class="sh_value border_left">O</div>
              <div class="sh_value">G</div>
              <div class="sh_value">B</div>
              <div class="sh_value">M</div>
              <div class="sh_value">A</div>
              <div class="sh_value">Y</div>
              <div class="sh_value">Av</div>
              <div class="sh_value">P</div>
              <div class="sh_value border_left">O</div>
              <div class="sh_value">G</div>
              <div class="sh_value">B</div>
              <div class="sh_value">M</div>
              <div class="sh_value">A</div>
              <div class="sh_value">Y</div>
              <div class="sh_value">Av</div>
              <div class="sh_value">P</div>
              <div class="sh_value border_left">O</div>
              <div class="sh_value">G</div>
              <div class="sh_value">B</div>
              <div class="sh_value">M</div>
              <div class="sh_value">A</div>
              <div class="sh_value">Y</div>
              <div class="sh_value">Av</div>
              <div class="sh_value">P</div>
            </div>
            <div
                class="standing_row"
                v-for="(t, ti) in compactStandings"
                :key="ti"
                :id="`lds-${t.teamId}`"
            >
              <div class="sh_num">{{ ti + 1 }}</div>
              <div>
                <img class="team_logo" :src="teamLogo(t.teamId)" alt=""/>
              </div>
              <div class="sh_team_name">
                <span @click="toTeamDetail(t.teamId)">{{ t.teamName }}</span>
              </div>
              <div class="sh_value border_left font_weight_900">{{ t.overall.played }}</div>
              <div class="sh_value">{{ t.overall.win }}</div>
              <div class="sh_value">{{ t.overall.draw }}</div>
              <div class="sh_value">{{ t.overall.loss }}</div>
              <div class="sh_value">{{ t.overall.goalsFor }}</div>
              <div class="sh_value">{{ t.overall.goalsAgainst }}</div>
              <div class="sh_value">{{ t.overall.goalDiff }}</div>
              <div class="sh_value font_weight_900">{{ t.overall.points }}</div>
              <div class="sh_value border_left font_weight_900">{{ t.home.played }}</div>
              <div class="sh_value">{{ t.home.win }}</div>
              <div class="sh_value">{{ t.home.draw }}</div>
              <div class="sh_value">{{ t.home.loss }}</div>
              <div class="sh_value">{{ t.home.goalsFor }}</div>
              <div class="sh_value">{{ t.home.goalsAgainst }}</div>
              <div class="sh_value">{{ t.home.goalDiff }}</div>
              <div class="sh_value font_weight_900">{{ t.home.points }}</div>
              <div class="sh_value border_left font_weight_900">{{ t.away.played }}</div>
              <div class="sh_value">{{ t.away.win }}</div>
              <div class="sh_value">{{ t.away.draw }}</div>
              <div class="sh_value">{{ t.away.loss }}</div>
              <div class="sh_value">{{ t.away.goalsFor }}</div>
              <div class="sh_value">{{ t.away.goalsAgainst }}</div>
              <div class="sh_value">{{ t.away.goalDiff }}</div>
              <div class="sh_value font_weight_900">{{ t.away.points }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="standings[0].data.length > 0" class="standing_container">
            <div class="season_name _header _accent&#45;&#45;text" v-if="this.tr === getMenuItems()">
              <div></div>
              <div></div>
              <div v-if="tab === 1">Genel</div>
              <div v-if="tab === 2">İç Saha</div>
              <div v-if="tab === 3">Dış Saha</div>
            </div>
            <div class="season_name _header _accent&#45;&#45;text" v-if="this.en === getMenuItems()">
              <div></div>
              <div></div>
              <div v-if="tab === 1">General</div>
              <div v-if="tab === 2">Home Field</div>
              <div v-if="tab === 3">Away Field</div>
            </div>
            <div class="standing_header _header _accent&#45;&#45;text">
              <div class="sh_num">#</div>
              <div class="sh_team_name">Takım</div>
              <div class="sh_value border_left">O</div>
              <div class="sh_value">G</div>
              <div class="sh_value">B</div>
              <div class="sh_value">M</div>
              <div class="sh_value">A</div>
              <div class="sh_value">Y</div>
              <div class="sh_value">Av</div>
              <div class="sh_value">P</div>
            </div>
            <div class="standing_row" v-for="(t,ti) in standings[tab - 1].data" :key="ti">
              <div class="sh_num">{{ t.rankTotal }}</div>
              <div>
                <img class="team_logo" :src="teamLogo(t.teamId)" alt=""/>
              </div>
              <div class="sh_team_name">
                <span @click="toTeamDetail(t.teamId)">{{ t.teamName }}</span>
              </div>
              <div class="sh_value border_left font_weight_900">{{ t.played }}</div>
              <div class="sh_value">{{ t.win }}</div>
              <div class="sh_value">{{ t.draw }}</div>
              <div class="sh_value">{{ t.loss }}</div>
              <div class="sh_value">{{ t.goalsFor }}</div>
              <div class="sh_value">{{ t.goalsAgainst }}</div>
              <div class="sh_value">{{ t.goalDiff }}</div>
              <div class="sh_value font_weight_900">{{ t.points }}</div>
            </div>
          </div>
        </template>
      </template>-->
      <template>
        <div class="standing_container">
          <div class="season_name _header _accent--text">
            <div></div>
            <div></div>
            <div v-if="tab === 0">Genel</div>
            <div @click="tab = 1" v-if="tab === 1">İç Saha</div>
            <div @click="tab = 2" v-if="tab === 2">Dış Saha</div>
          </div>
          <div class="standing_header _header _accent--text">
            <div class="sh_num">#</div>
            <div class="sh_team_name">Takım</div>
            <div class="sh_value border_left">O</div>
            <div class="sh_value">G</div>
            <div class="sh_value">B</div>
            <div class="sh_value">M</div>
            <div class="sh_value">A</div>
            <div class="sh_value">Y</div>
            <div class="sh_value">Av</div>
            <div class="sh_value">P</div>
          </div>
          <div v-if="tab === 0">
            <div
                class="standing_row" v-for="(t,ti) in standings[tab].data" :key="ti"
                :id="`lds-${t.teamId}`"
            >
              <div class="sh_num">{{ t.position }}</div>
              <div>
                <img class="team_logo" :src="teamLogo(t.teamId)" alt=""/>
              </div>
              <div class="sh_team_name">
                <span @click="toTeamDetail(t.teamId)">{{ t.teamName }}</span>
              </div>
              <div class="sh_value border_left font_weight_900">{{ t.played }}</div>
              <div class="sh_value">{{ t.win }}</div>
              <div class="sh_value">{{ t.draw }}</div>
              <div class="sh_value">{{ t.loss }}</div>
              <div class="sh_value">{{ t.goalsFor }}</div>
              <div class="sh_value">{{ t.goalsAgainst }}</div>
              <div class="sh_value">{{ t.goalsDiff }}</div>
              <div class="sh_value font_weight_900">{{ t.points }}</div>
            </div>
          </div>
          <div v-if="tab === 1">
            <div
                class="standing_row" v-for="(t,ti) in standings[0].data" :key="ti"
                :id="`lds-${t.teamId}`"
            >
              <div class="sh_num">{{ t.position }}</div>
              <div>
                <img class="team_logo" :src="teamLogo(t.teamId)" alt=""/>
              </div>
              <div class="sh_team_name">
                <span @click="toTeamDetail(t.teamId)">{{ t.teamName }}</span>
              </div>
              <div class="sh_value border_left font_weight_900">{{ t.hplayed }}</div>
              <div class="sh_value">{{ t.hwin }}</div>
              <div class="sh_value">{{ t.hdraw }}</div>
              <div class="sh_value">{{ t.hloss }}</div>
              <div class="sh_value">{{ t.hgoalsFor }}</div>
              <div class="sh_value">{{ t.hgoalsAgainst }}</div>
              <div class="sh_value">{{ t.hgoalsDiff }}</div>
              <div class="sh_value font_weight_900">{{ t.hpoints }}</div>
            </div>
          </div>
          <div v-if="tab === 2">
            <div
                class="standing_row" v-for="(t,ti) in standings[0].data" :key="ti"
                :id="`lds-${t.teamId}`"
            >
              <div class="sh_num">{{ t.position }}</div>
              <div>
                <img class="team_logo" :src="teamLogo(t.teamId)" alt=""/>
              </div>
              <div class="sh_team_name">
                <span @click="toTeamDetail(t.teamId)">{{ t.teamName }}</span>
              </div>
              <div class="sh_value border_left font_weight_900">{{ t.aplayed }}</div>
              <div class="sh_value">{{ t.awin }}</div>
              <div class="sh_value">{{ t.adraw }}</div>
              <div class="sh_value">{{ t.aloss }}</div>
              <div class="sh_value">{{ t.agoalsFor }}</div>
              <div class="sh_value">{{ t.agoalsAgainst }}</div>
              <div class="sh_value">{{ t.agoalsDiff }}</div>
              <div class="sh_value font_weight_900">{{ t.apoints }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
<!--    <template v-if="nextWeek">
      <h2 class="fv_title">{{ nextWeek.round }}. Hafta Fikstür</h2>
      <div class="c_header _header _accent&#45;&#45;text">
        <div class="ch_date">Tarih</div>
        <div class="ch_home_team"></div>
        <div class="ch_score"></div>
        <div class="ch_away_team"></div>
        <div class="ch_half_time"></div>
        <div class="ch_corner"></div>
        <div class="ch_under_over"></div>
        <div class="ch_bts"></div>
      </div>
      <div
          class="c_match"
          v-for="match in nextWeek.matches"
          :key="match.id"
          @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
      >
        <div class="cm_date">{{ matchDate(match.schd) }}</div>
        <div class="cards">
          <div v-if="match.h_y_c > 0" class="card yellow-card">{{ match.h_y_c }}</div>
          <div v-if="match.h_r_c > 0" class="card red-card">{{ match.h_r_c }}</div>
        </div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.hti)">{{ match.ht }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDCompare', params: {id: match.id}}" target="_blank">-</router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.ati)">{{ match.at }}</span>
        </div>
        <div class="cards">
          <div v-if="match.a_y_c > 0" class="card yellow-card">{{ match.a_y_c }}</div>
          <div v-if="match.a_r_c > 0" class="card red-card">{{ match.a_r_c }}</div>
        </div>
        <div class="cm_half_time"></div>
        <div class="cm_corner"></div>
        <div class="cm_under_over"></div>
        <div class="cm_bts"></div>
        <div class="cm_match_status"></div>
      </div>
    </template>-->
<!--    <template v-if="lastWeek">
      <h2 class="fv_title">{{ lastWeek.round }}. Hafta Sonuçlar</h2>
      <div class="c_header _header _accent&#45;&#45;text">
        <div class="ch_date">Tarih</div>
        <div class="ch_home_team"></div>
        <div class="ch_score"></div>
        <div class="ch_away_team"></div>
        <div class="ch_half_time">İY</div>
        <div class="ch_corner">Korner</div>
        <div class="ch_under_over">A/Ü</div>
        <div class="ch_bts">KG</div>
      </div>
      <div
          class="c_match"
          v-for="match in lastWeek.matches"
          :key="match.id"
          @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
      >
        <div class="cm_date">{{ matchDate(match.schd) }}</div>
        <div class="cards">
          <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
          <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
        </div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.hti)">{{ match.ht }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hts }} - {{ match.ats }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.ati)">{{ match.at }}</span>
        </div>
        <div class="cards">
          <div v-if="match.a_y_c > 0" class="card yellow-card">{{ match.a_y_c }}</div>
          <div v-if="match.a_r_c > 0" class="card red-card">{{ match.a_r_c }}</div>
        </div>
        <div class="cm_half_time">
          <span><span class="only_mobile">İY<br></span>{{ match.fphs }} - {{ match.fpas }}</span>
        </div>
        <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.h_c_k }} - {{ match.a_c_k }}
        </div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
        <div class="cm_match_status">
          <div></div>
        </div>
      </div>
    </template>-->
  </div>
</template>

<script>
import {fetchStandings} from "../../api";
import dayjs from "dayjs";

export default {
  name: "Standings",
  data: () => ({
    tr: false,
    en: false,
    tab: 0,
    webStandingTab: 0,
    haveGroups: false,
    standings: [
      {header: 'Genel', data: []},
      {header: 'İç Saha', data: []},
      {header: 'Dış Saha', data: []},
    ],
    compactStandings: [],
    groups: null,
    selectedGroupModal: null,
    groupList: [],
    league: [],
    lastWeek: null,
    nextWeek: null,
    seasonId: null
  }),

  created() {
    this.fetchStandings();
  },

  methods: {
    getMenuItems() {
      if (localStorage.getItem('language') === 'tr') {
        return this.tr = true;
      } else {
        return this.en = true;
      }
    },
    async fetchStandings() {
      const data = await fetchStandings(this.$route.params.seasonId)
      this.seasonId = this.$route.params.seasonId;
      if (data.data.data) {
        if (data.data.data.groups.length > 1 && data.data.data.groups.length) {
          this.haveGroups = true;
          data.data.data.groups.forEach((g) => {
            this.groupList.push(g.name);
          });
          this.selectedGroupModal = this.groupList[0];
          this.groups = data.data.data.groups;
        } else {
          this.haveGroups = false;
          //this.nextWeek = data.data.data.nextWeek;
          //this.lastWeek = data.data.data.lastWeek;
          if (data.data.data.groups[0]) {
            this.standings[0].data = data.data.data.groups[0].rows;
            //this.standings[1].data = data.data.data.groups[0].rowsHome;
            //this.standings[2].data = data.data.data.groups[0].rowsAway;
            this.compactStandings = this.compactifyStandings(data.data.data.groups[0]);
          }
        }
      }
    },

    teamLogo(id) {
      return `https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${id}.png`;
    },

    /*compactifyStandings(standings) {
      let list = [];
      standings.rowsTotal.forEach(item => {
        list.push({
          teamId: item.teamId,
          rank: item.rankTotal,
          teamName: item.teamName,
          overall: {
            played: item.played,
            win: item.won,
            draw: item.drawn,
            loss: item.lost,
            goalsFor: item.goalsFor,
            goalsAgainst: item.goalsAgainst,
            goalDiff: item.goalDiff,
            points: item.points,
          }
        })
      });
      list.map(to => {
        standings.rowsHome.forEach(item => {
          if (to.teamId === item.teamId) {
            to.home = {
              played: item.played,
              win: item.won,
              draw: item.drawn,
              loss: item.lost,
              goalsFor: item.goalsFor,
              goalsAgainst: item.goalsAgainst,
              goalDiff: item.goalDiff,
              points: item.points,
            }
          }
        })
      });
      list.map(to => {
        standings.rowsAway.forEach(item => {
          if (to.teamId === item.teamId) {
            to.away = {
              played: item.played,
              win: item.won,
              draw: item.drawn,
              loss: item.lost,
              goalsFor: item.goalsFor,
              goalsAgainst: item.goalsAgainst,
              goalDiff: item.goalDiff,
              points: item.points,
            }
          }
        })
      });
      return list;
    },*/

    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },

    matchDate(value) {
      return dayjs(value).format("YYYY-MM-DD");
    },

    underOver(match) {
      if (match.f || match.l) {
        return (match.hts + match.ats) > 2.5 ?
            {value: 'Ü', style: 'success--text'} :
            {value: 'A', style: 'error--text'}
      } else {
        return {value: '', style: ''};
      }
    },

    bts(match) {
      if (match.f || match.l) {
        return (match.hts > 0 && match.ats > 0) ?
            {value: 'Var', style: 'success--text'} :
            {value: 'Yok', style: 'error--text'}
      } else {
        return {value: '', style: ''};
      }

    },

    mouseOverMatch(match) {
      let home = document.getElementById(`lds-${match.hti}`);
      let away = document.getElementById(`lds-${match.ati}`);
      if (home && away) {
        home.style.backgroundColor = '#e7e7e7';
        away.style.backgroundColor = '#e7e7e7';
      }
    },
    mouseLeaveMatch(match) {
      let home = document.getElementById(`lds-${match.hti}`);
      let away = document.getElementById(`lds-${match.ati}`);
      if (home && away) {
        home.style.backgroundColor = 'transparent';
        away.style.backgroundColor = 'transparent';
      }
    },
  },

  watch: {
    $route(oldVal, newVal) {
      if (oldVal.params.seasonId !== newVal.params.seasonId) {
        this.tab = 0;
        this.fetchStandings();
      }
    },

    selectedGroupModal(value) {
      this.groups.forEach((g) => {
        if (g.groupName === value) {
          this.standings[0].data = g.overall;
          this.standings[1].data = g.home;
          this.standings[2].data = g.away;
        }
      });
    }
  }
}
</script>

<style scoped>
.standing_header {
  padding: 0 10px;
  line-height: 24px;
  display: grid;
  /*grid-template-columns: 24px minmax(84px, 300px) repeat(24, minmax(24px, auto));*/
  grid-template-columns: 24px minmax(84px, 149px) repeat(8, minmax(70px,auto));
}

.season_name {
  display: grid;
  /*grid-template-rows: 23px;*/
  line-height: 23px;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 1px solid #edf2f4;
  /*grid-template-columns: 24px minmax(84px, 300px) repeat(3, minmax(192px, auto));*/
}

.sh_team_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.border_left {
  border-left: 1px solid #edf2f4;
}

.border_right {
  border-left: 1px solid #edf2f4;
}

.standing_row .sh_value {
  color: grey;
}

.sh_value {
  text-align: center;
}

.standing_row:hover {
  background-color: #d7d7d7;
}

.season_name div {
  text-align: center;
}

.standing_row {
  display: grid;
  line-height: 24px;
  height: 26px;
  padding: 0 10px;
  /*grid-template-columns: 24px 24px minmax(60px, 300px) repeat(24, minmax(24px, auto));*/
  grid-template-columns: 24px 24px minmax(60px, 125px) repeat(8, minmax(70px,auto));
}

.filter_bar {
  padding-right: 10px;
  padding-left: 10px;
}

.sub_tab {
  text-transform: capitalize;
  letter-spacing: 0;
  font-size: 12px;
  padding: 0 7px;
}

.team_logo {
  width: 20px;
  height: 20px;
  margin: 2px 0;
}

.select_container {
  display: flex;
}

.league_select {
  width: 80px;
  height: 24px;
  line-height: 24px;
  display: grid;
  grid-template-columns: auto 24px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #41a775;
  cursor: pointer;
  margin: 0 5px;
  padding-left: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.league_select div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*RESULTS & STANDINGS*/
.cm_score, .cm_half_time, .cm_bts, .cm_under_over, .ch_bts, .ch_under_over, .ch_corner, .cm_corner, .ch_half_time {
  text-align: center;
}

.cm_bts, .cm_under_over {
  font-weight: bold;
}

.cm_score {
  font-weight: 800;
}

.cm_half_time, .cm_corner {
  color: #b2b2b2;
}

.cm_home_team, .cm_away_team {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cm_home_team span, .cm_away_team span {
  cursor: pointer;
}

.font_weight_900 {
  font-weight: 900;
  color: #2a2a2a !important;
}

@media only screen and (min-width: 800px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(120px, 300px) 50px minmax(120px, 300px) 45px 45px 30px 30px;
  }

  .c_header_league {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: auto 45px 45px 30px 30px;
  }

  .c_match {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px 36px minmax(84px, 300px) 50px minmax(84px, 300px) 36px 45px 45px 30px 30px;
  }

  .cm_home_team {
    text-align: right;
  }

  .cm_home_team .cards {
    margin-left: auto;
  }

  .cm_half_time .only_mobile, .cm_corner .only_mobile {
    display: none;
  }

  .sub_tab.only_mobile {
    margin-left: auto;
  }


  .scroll_on_overflow {
    overflow-y: hidden;
    overflow-x: hidden;
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px;
    grid-template-areas: "date teams score underover bts";
  }

  .scroll_on_overflow {
    overflow-y: hidden;
    overflow-x: auto;
    display: block;
  }

  .ch_date {
    grid-area: date
  }

  .ch_score {
    grid-area: score
  }

  .ch_half_time {
    grid-area: halftime
  }

  .ch_under_over {
    grid-area: underover
  }

  .ch_bts {
    grid-area: bts
  }

  .ch_away_team {
    display: none
  }

  .ch_home_team {
    grid-area: teams
  }

  .c_match {
    display: grid;
    grid-template-rows: 23px 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px;
    grid-template-areas: "date hometeam score underover bts" "date awayteam score underover bts";
  }

  .ch_half_time, .ch_corner {
    display: none;
  }

  .cm_date {
    padding-top: 12px;
    grid-area: date;
  }

  .cm_home_team {
    grid-area: hometeam;
  }

  .cm_away_team {
    grid-area: awayteam;
  }

  .cm_score {
    padding-top: 12px;
    grid-area: score;
  }

  .cm_half_time {
    display: none;
  }

  .cm_corner {
    display: none;
  }

  .cm_under_over {
    padding-top: 12px;
    grid-area: underover;
  }

  .cm_bts {
    padding-top: 12px;
    grid-area: bts;
  }

  .cards {
    display: none;
  }

  .cm_match_status {
    display: none;
  }
}

@media only screen and (max-width: 426px) {
  .standing_header {
    padding: 0 10px;
    line-height: 24px;
    display: grid;
    /*grid-template-columns: 24px minmax(84px, 300px) repeat(24, minmax(24px, auto));*/
    grid-template-columns: 24px minmax(84px, 149px) repeat(8, minmax(24px,auto));
  }
  .standing_row {
    display: grid;
    line-height: 24px;
    height: 26px;
    padding: 0 10px;
    /*grid-template-columns: 24px 24px minmax(60px, 300px) repeat(24, minmax(24px, auto));*/
    grid-template-columns: 24px 24px minmax(60px, 125px) repeat(8, minmax(24px,auto));
  }
}


@media only screen and (max-width: 376px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px;
    grid-template-areas: "date teams score underover bts";
  }

  .scroll_on_overflow {
    overflow-y: hidden;
    overflow-x: auto;
    display: block;
  }

  .ch_date {
    grid-area: date
  }

  .ch_score {
    grid-area: score
  }

  .ch_half_time {
    grid-area: halftime
  }

  .ch_under_over {
    grid-area: underover
  }

  .ch_bts {
    grid-area: bts
  }

  .ch_away_team {
    display: none
  }

  .ch_home_team {
    grid-area: teams
  }

  .c_match {
    display: grid;
    grid-template-rows: 23px 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px;
    grid-template-areas: "date hometeam score underover bts" "date awayteam score underover bts";
  }

  .ch_half_time, .ch_corner {
    display: none;
  }

  .cm_date {
    padding-top: 12px;
    grid-area: date;
  }

  .cm_home_team {
    grid-area: hometeam;
  }

  .cm_away_team {
    grid-area: awayteam;
  }

  .cm_score {
    padding-top: 12px;
    grid-area: score;
  }

  .cm_half_time {
    display: none;
  }

  .cm_corner {
    display: none;
  }

  .cm_under_over {
    padding-top: 12px;
    grid-area: underover;
  }

  .cm_bts {
    padding-top: 12px;
    grid-area: bts;
  }

  .cards {
    display: none;
  }

  .cm_match_status {
    display: none;
  }
}
</style>
