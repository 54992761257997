<template>
    <div class="stats_container">
        <v-row>
            <v-col cols="12" md="5" class="ml-auto">
                <div class="stat_card">
                    <div class="stat_header _header _accent--text ">Genel İstatistikler</div>
                    <div class="stat_row" v-for="(gs, gsi) in GENERAL_STATS" :key="gsi">
                        <div>{{gs.text}}</div>
                        <div class="stat_row_value">{{leagueInfo.competition[gs.value]}}</div>
                    </div>
                </div>
                <div class="stat_card">
                    <div class="stat_header _header _accent--text ">Kazanma İstatistikleri</div>
                    <div class="stat_row" v-for="(ws, wsi) in WIN_STATS" :key="wsi">
                        <div>{{ws.text}}</div>
                        <div class="stat_row_value">%{{leagueInfo.competition[ws.value]}}</div>
                    </div>
                </div>
                <div class="stat_card">
                    <div class="stat_header _header _accent--text ">Korner İstatistikleri</div>
                    <div class="stat_row" v-for="(cs, csi) in CORNER_STATS" :key="csi">
                        <div>{{cs.text}}</div>
                        <div class="stat_row_value">%{{leagueInfo.competition[cs.value]}}</div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="5" class="mr-auto">
                <div class="stat_card">
                    <div class="stat_header _header _accent--text ">Gol İstatistikleri</div>
                    <div class="stat_row" v-for="(goals, goalsi) in GOAL_STATS" :key="goalsi">
                        <div>{{goals.text}}</div>
                        <div class="stat_row_value">%{{leagueInfo.competition[goals.value]}}</div>
                    </div>
                </div>
                <div class="stat_card">
                    <div class="stat_header _header _accent--text ">Kart İstatistikleri</div>
                    <div class="stat_row" v-for="(cas, casi) in CARD_STATS" :key="casi">
                        <div>{{cas.text}}</div>
                        <div class="stat_row_value">%{{leagueInfo[cas.value]}}</div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "Stats",
        props: ['leagueInfo'],
        data() {
            return {
                GENERAL_STATS, GOAL_STATS, CARD_STATS, CORNER_STATS, WIN_STATS,
            }
        },
    }

    const GENERAL_STATS = [
        {text: 'Gol', value: 'goalAvg'},
        {text: 'Korner', value: 'cornerAvg'},
        {text: 'Sarı Kart', value: 'yellowCardAvg'},
        {text: 'Kart', value: 'totalCardAvg'},
    ];

    const GOAL_STATS = [
        {text: '1.Y 0.5 Gol Üstü', value: 'over1H05GoalsPct'},
        {text: '2.Y 0.5 Gol Üstü', value: 'over2H05GoalsPct'},
        {text: '1.5 Gol Üstü', value: 'over15GoalsPct'},
        {text: '2.5 Gol Üstü', value: 'over25GoalsPct'},
        {text: 'KG Var', value: 'bttsPct'},
        {text: 'Her İki Yarıda Gol', value: 'bhgPct'},
    ];

    const CORNER_STATS = [
        {text: '8.5 Korner Üstü', value: 'over85CornerPct'},
        {text: '9.5 Korner Üstü', value: 'over95CornerPct'},
        {text: '10.5 Korner Üstü', value: 'over105CornerPct'},
    ];

    const WIN_STATS = [
        {text: 'Ev Sahibi', value: 'goalAvg'},
        {text: 'Deplasman', value: 'cornerAvg'},
        {text: 'Beraberlik', value: 'yellowCardAvg'},
    ];

    const CARD_STATS = [
        {text: '3.5 Sarı Kart Üstü', value: 'over35YellowCardPct'},
        {text: '4.5 Sarı Kart Üstü', value: 'over45YellowCardPct'},
        {text: '5.5 Sarı Kart Üstü', value: 'over55YellowCardPct'},
        {text: '3.5 Kart Üstü', value: 'over35CardPct'},
        {text: '4.5 Kart Üstü', value: 'over45CardPct'},
        {text: '5.5 Kart Üstü', value: 'over55CardPct'},
    ];
</script>

<style scoped>
    .stats_container {
        padding: 10px 12px;
    }

    .stat_card{
        margin-bottom: 15px;
    }

    .stat_header {
        width: 100%;
        height: 23px;
        line-height: 23px;
        color: #ffffff;
        text-align: center;
        border: 1px solid black;
        box-sizing: border-box;
    }

    .stat_row {
        display: grid;
        padding: 0 10px;
        grid-template-columns: 50% 50%;
        grid-template-rows: 23px;
        line-height: 23px;
        border-bottom: 1px solid #edf2f4;
        border-left: 1px solid #edf2f4;
        border-right: 1px solid #edf2f4;
    }

    .stat_row_value {
        text-align: right;
    }

</style>
