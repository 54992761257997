<template>
  <div class="const_width">
    <div class="left_part">
      <div class="content_container">
        <div class="league_header">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                  v-if="!league"
                  type="image"
                  height="70"
                  tile
              ></v-skeleton-loader>
              <div v-if="league" class="league_header_left">
                <div>
                  <img class="league-logo" :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/categories/${league.competition.areaId}.png`"
                       alt=""/>
                </div>
                <div class="league_title">
                  <p class="league_title_name">{{ league.competition.area }} - {{ league.competition.league }}</p>
<!--                  <p class="league_dates">{{ league.currentSeason.startDate }} /
                    {{ league.currentSeason.endDate }}</p>-->
                </div>
<!--                <div class="league_status">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-progress-linear
                          class="w-50"
                          color="_c1"
                          height="15"
                          :value="league.competition.ocId*100/league.competition.opId"
                          rounded
                          v-on="on"
                      >
                        <template v-slot>
                          <small class="white&#45;&#45;text">{{ league.competition.ocId }} /
                            {{ league.competition.opId }}</small>
                        </template>
                      </v-progress-linear>
                    </template>
                    <p class="white&#45;&#45;text mb-1">Toplam Maç Sayısı: <b>{{ league.competition.opId }}</b>
                    </p>
                    <p class="white&#45;&#45;text mb-1">Oynanan Maç Sayısı: <b>{{ league.competition.ocId }}</b></p>
                  </v-tooltip>
                </div>-->
              </div>
            </v-col>
            <v-col class="league-list-section" cols="12" md="6">
              <div class="select_container" v-if="this.tr === getMenuItems()">
                <v-menu offset-y max-height="300" max-width="200">
                  <template v-slot:activator="{ on }">
                    <div class="league_select" v-on="on">
                      <div>Diğer Ligler</div>
                      <v-icon>arrow_drop_down</v-icon>
                    </div>
                  </template>
                  <v-list dense>
                        <option class="options"
                                v-for="(l, li) in categoryLeagues2"
                                :key="li" :value=l.league
                                @click="toLeague(l)"
                        >
                          {{l.league}}
                        </option>
                  </v-list>
                </v-menu>
                <v-menu offset-y max-height="300" max-width="300">
                  <template v-slot:activator="{ on }">
                    <div class="league_select" v-on="on">
                      <div>Popüler Ligler</div>
                      <v-icon>arrow_drop_down</v-icon>
                    </div>
                  </template>
                  <v-list dense>
                    <v-list-item
                        v-for="(l, li) in popularLeagues"
                        :key="li"
                        @click="toLeague(l)"
                    >
                      <v-list-item-title>{{ l.league }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu :close-on-content-click="false" v-model="countriesModel" offset-y
                        max-height="300" max-width="200">
                  <template v-slot:activator="{ on }">
                    <div class="league_select" v-on="on">
                      <div>Ülkeler</div>
                      <v-icon>arrow_drop_down</v-icon>
                    </div>
                  </template>
                  <v-list dense>
                    <v-list-group
                        v-for="(c,ci) in countries"
                        :key="ci"
                        no-action
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title v-text="c.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                          v-for="(l,li) in c.leagues"
                          :key="li"
                          @click="toLeague(l)"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="l.league"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-menu>
              </div>
              <div class="select_container" v-if="this.en === getMenuItems()">
                <v-menu offset-y max-height="300" max-width="200">
                  <template v-slot:activator="{ on }">
                    <div class="league_select" v-on="on">
                      <div>Other Leageus</div>
                      <v-icon>arrow_drop_down</v-icon>
                    </div>
                  </template>
                  <v-list dense>
                    <option class="options"
                            v-for="(l, li) in categoryLeagues2"
                            :key="li" :value=l.league
                            @click="toLeague(l)"
                    >
                      {{l.league}}
                    </option>
                  </v-list>
                </v-menu>
                <v-menu offset-y max-height="300" max-width="300">
                  <template v-slot:activator="{ on }">
                    <div class="league_select" v-on="on">
                      <div>Popular Leagues</div>
                      <v-icon>arrow_drop_down</v-icon>
                    </div>
                  </template>
                  <v-list dense>
                    <v-list-item
                        v-for="(l, li) in popularLeagues"
                        :key="li"
                        @click="toLeague(l)"
                    >
                      <v-list-item-title>{{ l.league }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu :close-on-content-click="false" v-model="countriesModel" offset-y
                        max-height="300" max-width="200">
                  <template v-slot:activator="{ on }">
                    <div class="league_select" v-on="on">
                      <div>Countries</div>
                      <v-icon>arrow_drop_down</v-icon>
                    </div>
                  </template>
                  <v-list dense>
                    <v-list-group
                        v-for="(c,ci) in countries"
                        :key="ci"
                        no-action
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title v-text="c.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                          v-for="(l,li) in c.leagues"
                          :key="li"
                          @click="toLeague(l)"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="l.league"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="tabs-for-web">
          <v-tabs centered slider-size="3" v-model="tabModel" height="32" color="black" v-if="this.tr === getMenuItems()">
            <v-tab class="text-capitalize" v-for="(item,ii) in tabsTR" :key="ii">
              {{ item.text }}
            </v-tab>
          </v-tabs>
          <v-tabs centered slider-size="3" v-model="tabModel" height="32" color="black" v-if="this.en === getMenuItems()">
            <v-tab class="text-capitalize" v-for="(item,ii) in tabsEN" :key="ii">
              {{ item.text }}
            </v-tab>
          </v-tabs>
          <v-tabs slider-color="#3063b3" v-if="tabModel===3 && this.en === getMenuItems()" centered slider-size="3" height="32" color="black" >
            <v-tab @click="toggle = item.toggle" class="text-capitalize stats-tabs" v-for="(item,i) in statsTabsEN" :key="i">
              {{item.text}}
            </v-tab>
          </v-tabs>
          <v-tabs slider-color="#3063b3" v-if="tabModel===3 && this.tr === getMenuItems()" centered slider-size="3" height="32" color="black" >
            <v-tab @click="toggle = item.toggle" class="text-capitalize stats-tabs" v-for="(item,i) in statsTabsTR" :key="i">
              {{item.text}}
            </v-tab>
          </v-tabs>
        </div>
        <div class="tabs-for-mobile">
          <v-tabs background-color="#dce0e7" centered slider-size="3" v-model="tabModel" height="32" color="black" v-if="this.tr === getMenuItems()">
            <v-tab class="text-capitalize" v-for="(item,ii) in tabsTR" :key="ii">
              {{ item.text }}
            </v-tab>
          </v-tabs>
          <v-tabs background-color="#dce0e7" centered slider-size="3" v-model="tabModel" height="32" color="black" v-if="this.en === getMenuItems()">
            <v-tab class="text-capitalize" v-for="(item,ii) in tabsEN" :key="ii">
              {{ item.text }}
            </v-tab>
          </v-tabs>
          <v-tabs slider-color="#3063b3" v-if="tabModel===3 && this.en === getMenuItems()" centered slider-size="3" height="32" color="black" >
            <v-tab @click="toggle = item.toggle" class="text-capitalize stats-tabs" v-for="(item,i) in statsTabsEN" :key="i">
              {{item.text}}
            </v-tab>
          </v-tabs>
          <v-tabs slider-color="#3063b3" v-if="tabModel===3 && this.tr === getMenuItems()" centered slider-size="3" height="32" color="black" >
            <v-tab @click="toggle = item.toggle" class="text-capitalize stats-tabs" v-for="(item,i) in statsTabsTR" :key="i">
              {{item.text}}
            </v-tab>
          </v-tabs>
        </div>
        <standings v-if="tabModel===0"/>
        <results-and-fixture v-if="tabModel===1 || tabModel ===2" :tab="tabModel-1"/>
        <StatsTable :league="league" :toggle="toggle" v-if="tabModel===3 && this.$route.params.seasonId"></StatsTable>
        <stats v-if="tabModel=== -1 && league" :league-info="league"/>
      </div>
    </div>
    <div class="right_part">
      <div class="leagues_bilyoner_container">
        <a href="https://app.adjust.com/azvq2wg?redirect=https%3A%2F%2Fwww.bilyoner.com%2Fiddaa%3Futm_source%3Dfutbolverileri" target="_blank">
          <v-img
              max-width="315"
              max-height="630"
              width="100%"
              src="@/assets/bilyoner/superLeague.png"
              lazy-src="@/assets/bilyoner/superLeague.png"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import api3, {fetchCompetition, fetchLeagueInfo, fetchPopularLeagues} from "../../api";
import Standings from "./Standings";
import ResultsAndFixture from "./ResultsAndFixture";
import Stats from "./Stats";
import StatsTable from "./StatsTable";

export default {
  name: "LeagueDetail",
  components: {Stats, ResultsAndFixture, Standings,StatsTable},
  data() {
    return {
      tr: false,
      en: false,
      toggle:0,
      popularLeagues: [],
      categoryLeagues: [],
      categoryLeagues2:[],
      countries: [],
      league: null,
      countriesModel: false,
      tabModel: 0,
      tabsTR: [
        {text: 'Puan Durumu', to: {params: {tab: undefined}}},
        {text: 'Sonuçlar', to: {params: {tab: 'results'}}},
        {text: 'Fikstür', to: {params: {tab: 'fixture'}}},
        {text: 'Detaylı İstatistikler', to: {params: {tab: 'statsTable'}}},
      ],
      tabsEN: [
        {text: 'Standings', to: {params: {tab: undefined}}},
        {text: 'Results', to: {params: {tab: 'results'}}},
        {text: 'Fixture', to: {params: {tab: 'fixture'}}},
        {text: 'Detailed Stats', to: {params: {tab: 'statsTable'}}},
      ],
      statsTabsEN: [
        {text: 'Goals', toggle: 0},
        {text: 'Corners ', toggle: 1},
        {text: 'Yellow Cards', toggle: 2},
        {text: 'Total Cards(Y:1, R:2)', toggle: 3},
      ],
      statsTabsTR: [
        {text: 'Gol', toggle: 0},
        {text: 'Korner ', toggle: 1},
        {text: 'Sarı Kart', toggle: 2},
        {text: 'Toplam Kart', toggle: 3},
      ]
    }
  },

  mounted() {
    this.fetchPopularLeagues();
    this.fetchCategoryLeagues();
    this.fetchCountries();
    if (!this.$route.params.categoryId || !this.$route.params.seasonId) {
      this.fetchDefaultLeague();
    } else {
      this.fetchDynamicData();
    }
  },

  methods: {
    getMenuItems() {
      if (localStorage.getItem('language') === 'tr') {
        return this.tr = true;
      } else {
        return this.en = true;
      }
    },
    async fetchDefaultLeague() {
      await api3.get(`api/v5/football/competition/default`)
          .then((data) => {
            if (data.data.success) {
              this.$router.push({
                params: {
                  categoryId: data.data.data.areaId,
                  seasonId: data.data.data.seasonId,
                  leagueId: data.data.data.leagueId
                }
              })
            }
          })
    },

    async fetchLeagueInfo() {
      this.league = null;
      const data = await fetchLeagueInfo(this.$route.params.leagueId);
      this.league = data.data.data;
    },

    async fetchCategoryLeagues() {
      const data = await fetchCompetition();
      this.categoryLeagues2 = []
      this.categoryLeagues = data.data.data.areas;
      for (let i = 0; i < this.categoryLeagues.length; i++) {
        let veri2 = this.categoryLeagues[i].leagues;
        for (let i = 0; i < veri2.length; i++) {
          this.categoryLeagues2.push(veri2[i])
        }
      }
    },

    async fetchPopularLeagues() {
      const data = await fetchPopularLeagues();
      this.popularLeagues = data.data.data;
    },

    async fetchCountries() {
      const data = await fetchCompetition();
      this.countries = data.data.data.areas;
    },

    fetchDynamicData() {
      this.fetchLeagueInfo();
    },

    toLeague(league) {
      if (this.countriesModel) {
        this.countriesModel = false;
      }
      this.$router.push({
        params: {
          categoryId: league.areaId,
          seasonId: league.seasonId,
          leagueId: league.leagueId
        }
      });
      //window.open(routeData.href, '_blank');
    },
  },

  watch: {
    $route(oldVal, newVal) {
      if (oldVal.params.seasonId !== newVal.params.seasonId) {
        this.fetchDynamicData();
      }
    }
  }
}
</script>

<style scoped>

.options {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1rem;
  min-height: 40px;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  place-items: center;
  width: auto;
}

.league-logo {
  width: 50px;
  height: 50px;
}

.options:hover {
  background-color: #fafafa;
}

.league_header {
  padding: 0 12px;
}

.tabs-for-mobile {
  display: none;
}

.league_header_left {
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: 50px 20px;
  grid-template-areas: "logo league" "bar bar";
}

.league_logo {
  display: grid;
  grid-area: logo;
  place-items: center;
}

.league_logo img {
  width: 50px;
  height: 40px;
}

.league_title {
  padding: 0 10px;
  grid-area: league;
  margin-top: 10px;
}

.league_status {
  padding-right: 15px;
  display: grid;
  align-items: center;
  grid-area: bar;
}

.league_title_name {
  font-size: 16px;
  margin: 4px 0 0;
}

.league_dates {
  font-size: 12px;
  margin: 0;
  color: #bbbbbb;
}

.select_container {
  display: flex;
}

.league_select {
  width: 100%;
  height: 24px;
  line-height: 24px;
  display: grid;
  grid-template-columns: auto 24px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #41a775;
  cursor: pointer;
  margin: 0 5px;
  padding-left: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.league_select div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 30px !important;
}

.leagues_bilyoner_container {
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 10px;
}

@media only screen and (max-width: 426px) {
  .league-list-section {
    display: none;
  }

  .tabs-for-web {
    display: none;
  }

  .tabs-for-mobile{
    display: flex;
    flex-direction: column;
  }

  .league_header_left {
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: none;
    grid-template-areas: "logo league" "bar bar";
  }

  .leagues_bilyoner_container {
    display: flex;
    justify-content: center;
  }
}
</style>
